* {
  box-sizing: border-box;
}

.root button {
  width: inherit;
  /* height: 4rem; */
  text-align: center;
  border-radius: 30px;
  -moz-border-radius: 30px;
  font-weight: bold;
  font-size: 20px;
  padding: 20px;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  color: #ffffff;
}

#dnf {
  background-color: #232f3e;
}

#dnf:hover,
#dnf:focus {
  background-color: #1c2532;
}

#dnf:active {
  background-color: #161e2a;
  transition: 0.5s;
  position: relative;
  top: 2px;
}

#dnf:disabled {
  background-color: #f1f3f3;
  color: #161e2d;
}

#carreset {
  background-color: #2074d5;
}

#carreset:hover,
#carreset:focus {
  background-color: #2864c8;
}

#carreset:active {
  background-color: #1f59b8;
  transition: 0.5s;
  position: relative;
  top: 2px;
}

#carreset:disabled {
  background-color: #f1f3f3;
  color: #161e2d;
}

#capturelap {
  background: #ff9900;
}

#capturelap:hover,
#capturelap:focus {
  background: #e68e22;
}

#capturelap:active {
  background: #db8113;
  transition: 0.5s;
  position: relative;
  top: 2px;
}

#capturelap:disabled {
  background-color: #f1f3f3;
  color: #161e2d;
}

#undoreset {
  background-color: #fbd8bf;
}

#undoreset:hover,
#undoreset:focus {
  background-color: #e9c6a9;
}

#undoreset:active {
  background-color: #d8b293;
  transition: 0.5s;
  position: relative;
  top: 2px;
}

#undoreset:disabled {
  background-color: #f1f3f3;
  color: #161e2d;
}

#undofalsefinish {
  background-color: #d4dada;
}

#undofalsefinish:hover,
#undofalsefinish:focus {
  background-color: #ced0d0;
}

#undofalsefinish:active {
  background-color: #c1c3c3;
  transition: 0.5s;
  position: relative;
  top: 2px;
}

#undofalsefinish:disabled {
  background-color: #f1f3f3;
  color: #161e2d;
}

#endrace {
  background-color: #161e2d;
}

#endrace:hover,
#endrace:focus {
  background-color: #121825;
}

#endrace:active {
  background-color: #0b0f18;
  transition: 0.5s;
  position: relative;
  top: 2px;
}

#endrace:disabled {
  background-color: #f1f3f3;
  color: #161e2d;
}

#resumerace {
  background: #ff9900;
}

#startrace {
  background-color: #ff9900;
}

#startrace:hover,
#startrace:focus {
  background-color: #e68e22;
}

#startrace:active {
  background-color: #db8113;
  transition: 0.5s;
  position: relative;
  top: 2px;
}
